export const errorMessageHandler = (errorMessage: string) => {
  switch (errorMessage) {
    case 'Invalid login data':
      return 'errorMsg.invalidLoginData';
    case 'prismaErrorCode: P2002':
      return 'errorMsg.emailInUse';
    default:
      if (errorMessage?.includes('prismaErrorCode')) {
        return 'errorMsg.databaseError';
      }

      return 'errorMsg.internalError';
  }
};
