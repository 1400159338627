import { UserActionType, UserDataWithRoles, RoleData } from 'src/@types/user';

export const getRolesThatAlreadyContainTheSelectedEvents = (
  eventIds: number[],
  permissions: number[],
  selectedUser: UserDataWithRoles,
  actionType?: UserActionType
) => {
  let alreadyExistingEventsInAnotherRole: number[] = [];

  const rolesThatAlreadyContainTheSelectedEvents = selectedUser?.roles
    .filter(
      (role) =>
        role.permissions.every((permission) => permissions.includes(permission)) &&
        permissions.length > role.permissions.length &&
        eventIds.some((event) => role.events.includes(event) || event === 0)
    )
    .map((role) => {
      const { id, events } = role;

      const willModifyEventsPermissions = events.filter((event) => eventIds.includes(event));

      const eventsToUpdate: number[] = [];
      const eventsToDelete: number[] = [];

      willModifyEventsPermissions.forEach((event) => {
        if (
          actionType === UserActionType.USER_EDIT_ROLE ||
          alreadyExistingEventsInAnotherRole.includes(event)
        ) {
          eventsToDelete.push(event);
        } else {
          alreadyExistingEventsInAnotherRole.push(event);
          eventsToUpdate.push(event);
        }
      });

      return {
        id,
        eventsToUpdate,
        eventsToDelete,
        hasToDelete:
          eventIds[0] === 0 ? true : willModifyEventsPermissions.length === events.length,
      };
    });

  return {
    alreadyExistingEventsInAnotherRole,
    rolesThatAlreadyContainTheSelectedEvents,
  };
};

export const getLowerLevelRoles = (permissions: number[], selectedUserRoles: RoleData[]) =>
  selectedUserRoles.filter(
    (role) =>
      role.permissions.every((permission) => permissions.includes(permission)) &&
      permissions?.length > role.permissions.length
  );

export const getHigherLevelRoles = (permissions: number[], selectedUserRoles?: RoleData[]) =>
  selectedUserRoles?.filter(
    (role) =>
      permissions?.every((permission) => role.permissions.includes(permission)) &&
      permissions?.length < role.permissions.length
  );

export const getEqualLevelRole = (
  permissions: number[],
  selectedRoleId: number,
  selectedUserRoles: RoleData[]
) =>
  selectedUserRoles.find(
    (role) =>
      permissions?.every((permission) => role.permissions.includes(permission)) &&
      permissions?.length === role.permissions.length &&
      selectedRoleId !== role.id
  );

export const userHasHigherOrEqualRole = (
  permissions: number[],
  eventIds: number[],
  selectedUserRoles: RoleData[]
) => {
  const hasEqualLevel = selectedUserRoles.some(
    (selectedUserRole) =>
      !!permissions.length &&
      permissions.every((permission) => selectedUserRole.permissions.includes(permission)) &&
      (selectedUserRole.isAllEvents ||
        eventIds.every((eventId) => selectedUserRole.events.includes(eventId))) &&
      permissions.length === selectedUserRole.permissions.length
  );

  const hasHigherLever = selectedUserRoles.some(
    (selectedUserRole) =>
      !!permissions.length &&
      permissions.every((permission) => selectedUserRole.permissions.includes(permission)) &&
      (selectedUserRole.isAllEvents ||
        eventIds.every((eventId) => selectedUserRole.events.includes(eventId))) &&
      permissions.length < selectedUserRole.permissions.length
  );

  return {
    hasEqualLevel,
    hasHigherLever,
  };
};
