import { Language } from './location';
import { SaveUserDto, UserData } from './user';

export type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      };
};

export type LoginData = {
  accessToken: string;
  refreshToken: string;
};

export type RefreshData = {
  accessToken: string;
};

export type AuthUser = null | UserData;

export type AuthState = {
  isAuthenticated: boolean;
  isInitialized: boolean;
  user: AuthUser;
};

export type JWTContextType = {
  isAuthenticated: boolean;
  isInitialized: boolean;
  user: AuthUser;
  method: 'jwt';
  login: (email: string, password: string, rememberMe: boolean, userAgent: string) => Promise<void>;
  register: (params: { saveUserDto: SaveUserDto; language: Language }) => Promise<UserData>;
  logout: () => Promise<void>;
  setUser: (user: AuthUser) => Promise<void>;
};

export enum PagePermissionEnum {
  ORGANIZER_DATA = 100,
  EVENT_CREATE = 101,
  EVENT_LIST = 102,
  EVENT_EDIT = 103,
  EVENT_HISTORY = 104,
  EVENT_LIMIT_MANAGEMENT = 105,
  STATISTICS_SALES = 111,
  STATISTICS_REPORT = 112,
  STATISTICS_CUSTOMIZED = 113,
  SETTLEMENT = 121,
  GUESTS_INVITE = 131,
  GUESTS_LIST = 132,
  GUESTS_LIMIT_MANAGEMENT = 133,
  GUESTS_SETTINGS = 134,
  GUESTS_ALL_USERS = 135,
  MARKETING_COUPON = 141,
  NEWSLETTER = 151,
  ENTRY_LIST = 201,
  ENTRY_EDIT = 202,
  ENTRY_DELETE = 203,
  USER_INVITE = 301,
  USER_LIST = 302,
  USER_EDIT = 303,
  USER_HISTORY = 304,
  NTAK_EDIT_LIST = 401,
  NTAK_DATA_READ = 402,
  NTAK_BOXOFFICE_EVENTS = 411,
  NTAK_BOXOFFICE_SETTINGS = 412,
}

export enum RoleEnum {
  FREE_ORGANIZER = 'ingyenesSzervezo',
  EVENT_ORGANIZER = 'rendezvenyszervezo',
  FINANCE = 'penzugy',
  MARKETING = 'marketing',
  CASHIER = 'penztaros',
  ENTRY_MANAGER = 'beleptetoManager',
  GUEST_TICKET_SENDER = 'tiszteletjegyKuldes',
  TICKET_TRACKING = 'jegyfogyasKovetes',
  ENTRY = 'belepteto',
}
